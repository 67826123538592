import request from '@/utils/request'

export function searchhouselist(data) {
	return request({
		url: '/house/searchhouselist',
		method: 'get',
		params: data
	})
}
export function getUserLevelcode(data) {
	return request({
		url: '/common/getUserLevelcode',
		method: 'get',
		params: data
	})
}
export function houseinfolist(data) {
	return request({
		url: '/house/houseinfolist',
		method: 'get',
		params: data
	})
}
export function searchHouseNumsTree(data) {
	return request({
		url: '/house/searchHouseNumsTree',
		method: 'get',
		params: data
	})
}
export function basePersonlist(data) {
	return request({
		url: '/person/basePersonlist',
		method: 'get',
		params: data
	})
}
export function baseexportPersonlist(data) {
	return request({
		url: '/person/baseexportPersonlist',
		method: 'get',
		params: data
	})
}
export function showlabel(data) {
	return request({
		url: '/label/showlabel',
		method: 'get',
		params: data
	})
}
export function searchPersonNumsTree(data) {
	return request({
		url: '/person/searchPersonNumsTree',
		method: 'get',
		params: data
	})
}

//人员筛查标签树
export function labelTree(data) {
	return request({
		url: '/person/labelTreeByType',
		method: 'get',
		params: data
	})
}

//房屋筛查标签树
export function houseLabelTree(data) {
	return request({
		url: '/house/labelTreeByType',
		method: 'get',
		params: data
	})
}

//网格标签树
export function netTree(data) {
	return request({
		url: '/person/searchGridPersonNumsTree',
		method: 'get',
		params: data
	})
}



//标签树下，人员标签数量树
export function searchPersonNumsTreeByItem(data) {
	return request({
		url: '/person/searchPersonNumsTreeByItem',
		method: 'get',
		params: data
	})
}


//标签树下，人员标签数量树点小区获取栋
export function searchBuildTreeByItem(data) {
	return request({
		url: '/person/searchBuildTreeByItem',
		method: 'get',
		params: data
	})
}

//标签树下，人员标签数量树点栋获取单元
export function searchDanyuanTreeByItem(data) {
	return request({
		url: '/person/searchDanyuanTreeByItem',
		method: 'get',
		params: data
	})
}



//标签树下，房屋标签数量树
export function searchHouseNumsTreeByItem(data) {
	return request({
		url: '/house/searchHouseNumsByItemTree',
		method: 'get',
		params: data
	})
}

//人员模版导出模版
export function personDownexcel(data) {
	return request({
		url: '/person/downexcel',
		method: 'post',
		responseType: "blob",
		params: data
	})
}

//人员信息导入
export function personImportData(form,data) {
	return request({
			url: '/person/importData',
			method: 'post',
			params:data,
			data: form
	})
}

//人员信息删除
export function personDelete(data) {
	return request({
			url: '/person/PersonDel',
			method: 'post',
			params:data
	})
}


export function personInfo(data) {
	return request({
		url: '/person/personInfo',
		method: 'get',
		params: data
	})
}

export function NineSiteTree(data) {
	return request({
		url: '/nineSite/NineSiteTree',
		method: 'get',
		params: data
	})
}

export function NineSitePage(data) {
	return request({
		url: '/nineSite/NineSitePage',
		method: 'get',
		params: data
	})
}

//九小场所导出
export function NineSiteExport(data) {
	return request({
		url: '/nineSite/ExportNineSite',
		method: 'get',
		params: data
	})
}


//房屋筛查列表导出
export function exportHouseList(params) {
  return request({
    url: '/house/pageExport',
    method: 'get',
    params: params
  })
}

export function getGridManlist(data) {
	return request({
		url: '/wangge/getGridManlist',
		method: 'get',
		params: data
	})
}
export function NineSiteUpManage(data) {
	return request({
		url: '/nineSite/NineSiteUpManage',
		method: 'get',
		params: data
	})
}
export function NineSiteDetail(data) {
	return request({
		url: '/nineSite/NineSiteDetail',
		method: 'get',
		params: data
	})
}
export function indexPeronRelation(data) {
	return request({
		url: '/guanxi/indexPeronRelation',
		method: 'get',
		params: data
	})
}



//房屋图型化展示
//树结构
export function searchHouseTree(data) {
	return request({
		url: '/daping/searchHouseTree',
		method: 'get',
		params: data
	})
}

//社区下数据
export function houseSheQuShow(data) {
	return request({
		url: '/daping/houseSheQuShow',
		method: 'get',
		params: data
	})
}

//小区下数据
export function houseXiaoQuShow(data) {
	return request({
		url: '/daping/houseXiaoQuShow',
		method: 'get',
		params: data
	})
}

//栋下数据
export function buildHouseNum(data) {
	return request({
		url: '/daping/BuildHouseNum',
		method: 'get',
		params: data
	})
}

//获取单元信息
export function buildFloor(data) {
	return request({
		url: '/daping/Buildfloor',
		method: 'get',
		params: data
	})
}

export function ExportbasePersonlist(data) {
	return request({
		url: '/person/ExportbasePersonlist',
		method: 'get',
		params: data
	})
}


//获取标签
export function LableQuery(data) {
	return request({
		url: '/personLabel/LableTreeQuery',
		method: 'get',
		params: data
	})
}
export function PersonUpdate(data) {
	return request({
		url: '/person/PersonUpdate',
		method: 'get',
		params: data
	})
}


//人员新增删除流水
export function doingPersonList(data) {
	return request({
		url: '/person/DoingPersonPage',
		method: 'get',
		params: data
	})
}

//人员新增删除流水
export function expordoingPersonList(data) {
	return request({
		url: '/person/DoingPersonPageExport',
		method: 'get',
		params: data
	})
}