<template>
	<div class="panebox">
		<div class="pane">
			<div class="pane_cont">
				<el-row :gutter="20">
					<el-col :span="12">
						<div>单位名称：<span>{{ taskInfo.realname }}</span></div>
					</el-col>
					<el-col :span="12">
						<div>店铺名称：<span>{{ taskInfo.showname }}</span></div>
					</el-col>
					<el-col :span="24">
						<div style="display: flex; align-items:flex-start;">店铺照片：<el-image 
                          style="width: 100px; height: 100px"
                          :src="taskInfo.showpathurl[0]" 
                          :preview-src-list="taskInfo.showpathurl">
                        </el-image></div>
					</el-col>
          <el-col :span="24">
						<div>实际经营地址：<span>{{ taskInfo.address }}</span></div>
					</el-col>
					<el-col :span="24" v-if="taskInfo.addressxy">
						<div id="nineSiteAddressMap" style="height: 200px; width: 98%; margin: 10px auto 20px;"></div>
					</el-col>
          <el-col :span="12">
						<div>是否有营业执照：<span>{{ taskInfo.realcodeflag }}</span></div>
					</el-col>
          <el-col :span="12">
						<div>统一社会信用代码：<span>{{ taskInfo.realcode }}</span></div>
					</el-col>
          <el-col :span="24">
            <div style="display: flex; align-items:flex-start;">营业执照：<el-image 
                          style="width: 100px; height: 100px"
                          :src="taskInfo.realpathurl[0]" 
                          :preview-src-list="taskInfo.realpathurl">
                        </el-image></div>
					</el-col>
          <el-col :span="12">
						<div>是否三合一场所：<span>{{ taskInfo.sanheyi }}</span></div>
					</el-col>
          <el-col :span="12">
						<div>房屋性质：<span>{{ taskInfo.housetype }}</span></div>
					</el-col>
          <el-col :span="24">
						<div>留宿人数：<span>{{ taskInfo.personcount }}</span></div>
					</el-col>
          <el-col :span="12">
						<div>场所类型：<span>{{ taskInfo.ninetypename }}</span></div>
					</el-col>
          <el-col :span="12">
						<div>场所小类型：<span>{{ taskInfo.childninetype }}</span></div>
					</el-col>         
					<el-col :span="12">
						<div>场所管理人：<span>{{ taskInfo.siteman }}</span></div>
					</el-col>
					<el-col :span="12">
						<div>场所人电话：<span>{{ taskInfo.sitemanphone }}</span></div>
					</el-col>
					<el-col :span="24">
						<div>备注信息：<span>{{ taskInfo.descinfo }}</span></div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
  import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import {
		nineSiteDetail
	} from '@/api/ninesite'
	export default {
		name: "detail",
		components: {},
		props: {
			taskId: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				taskInfo: {}
			}
		},
		watch: {
			taskId: {
				handler(newVal, oldVal) {
					if (newVal) {
						this.getInfo();
					}
				},
				immediate: true
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
      //加载地图
			getmap(center) {
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('nineSiteAddressMap', {
						center,
						zoom: 20,
					})

					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			//获取任务详情
			getInfo() {
				let that = this;
				nineSiteDetail({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					siteuuid: that.taskId
				}).then(res => {
					if (res.result == 200) {
						console.log(res)
						let site = res.detail.site;
						this.taskInfo = site;
            let center = site.addressxy ? site.addressxy.split(',') : [];
						this.getmap(center)
					} else {
						that.$message.warning(res.description)
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.pane {
		margin-bottom: 30px;
		border: 1px solid #ddd;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

		.pane_tit {
			padding: 10px 20px;
			border-bottom: 1px solid #ddd;
			background: #f9f9f9;
			font-size: 16px;
			color: #333;
		}

		.pane_cont {
			padding: 20px;
			background: #fff;

			.el-row {
				>div {
					margin-bottom: 14px;
					font-weight: bold;
					color: #333;

					span {
						font-weight: normal;
						color: #666;
					}
				}
			}

		}
	}

	.pagebox {
		padding-top: 20px;
	}
</style>