<template>
  <div class="wrapper">
    <el-row style="margin-bottom: 10px; text-align: right;">
      <el-button type="primary" @click="exportExl">导出</el-button>
    </el-row>

    <el-table
      :data="tableData"
      border
      id="table-out"
      show-summary
      style="width: 100%">
      <el-table-column
        prop="ninetypename"
        label="场所统称">
      </el-table-column>
      <el-table-column
        prop="childninetype"
        label="场所细分">
      </el-table-column>
      <el-table-column
        prop="nums"
        label="数量">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import {
		totalNineSite
	} from '@/api/ninesite'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      tableData: []
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getInfo();
  },
  computed:{},
  methods:{
    getInfo(){
      totalNineSite({
        userid: this.GLOBAL.adminId()
      }).then(res => {
       console.log(res)
       let list = res.detail.site;
       this.tableData = list;
      })
    },
    exportExl() {
      let xlsxParam = {
        raw: true
      }
      let wb = XLSX.utils.table_to_book(
        document.querySelector('#table-out'),
        xlsxParam
      )
      let wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: 'application/octet-stream'
          }),
          '辖区单位类型数量汇总.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout)
      }
      return wbout
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>