<template>
	<div class="home">
		<div class="left" v-loading="loading1" style="border: 1px solid #DCDFE6;">

			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps"
					:default-expand-all="true" :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{ node, data }" style="width: 100%;height: 100%;">
						<span style="width: 100%;height: 100%;line-height: 32px;">{{ data.tvalue }}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">

			<div class="transformer">
				<!-- <div class="topbox">
					
				</div> -->
				<div class="clearfix electric-select">
					<el-form :model="chaxun" :inline="true">
						<el-form-item label="店铺名称：">
							<el-input class="shuru" v-model="chaxun.showname" clearable></el-input>
						</el-form-item>
						<el-form-item label="店铺管理人：">
							<el-input class="shuru" v-model="chaxun.siteman" clearable></el-input>
						</el-form-item>
						<el-form-item label="管理人电话：">
							<el-input class="shuru" v-model="chaxun.sitemanphone" clearable
								oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"></el-input>
						</el-form-item>
						<el-form-item label="网格员：">
							<el-input class="shuru" v-model="chaxun.managename" clearable></el-input>
						</el-form-item>

						<el-form-item label="是否有营业执照：">
							<el-select v-model="chaxun.realcodeflag" clearable placeholder="请选择">
								<el-option label="有" value="有"></el-option>
								<el-option label="没有" value="没有"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="营业执照">
							<el-input class="shuru" v-model="chaxun.realcode" clearable></el-input>
						</el-form-item>

						<el-form-item label="是否三合一场所">
							<el-select v-model="chaxun.sanheyi" clearable placeholder="请选择">
								<el-option label="是" value="是"></el-option>
								<el-option label="不是" value="不是"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="小类型">
							<el-input class="shuru" v-model="chaxun.childninetype" clearable></el-input>
						</el-form-item>

						<el-form-item label="任务情况">
							<el-select v-model="chaxun.taskflag" clearable placeholder="请选择">
								<el-option label="已建任务" value="0"></el-option>
								<el-option label="未建任务" value="1"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="认领情况">
							<el-select v-model="chaxun.renlingflag" clearable placeholder="请选择">
								<el-option label="已认领" value="0"></el-option>
								<el-option label="未认领" value="1"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" @click="onsearch()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button type="primary" @click="exportNineSite()" :loading="exportLoading">
								<i class="el-icon-top"></i>
								导出
							</el-button>
							<el-button type="primary" @click="nineSiteFlow()"><i
									class="el-icon-tickets"></i> 场所操作流水</el-button>
							<el-button type="primary" @click="openTotalPage()" :loading="exportLoading">
								<i class="el-icon-date"></i>
								统计
							</el-button>
						</el-form-item>
					</el-form>

				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" border style="width: 100%;">
						<el-table-column type="index" width="70" align="center" height show-overflow-tooltip>
							<template scope="scope"><span>{{scope.$index+(currentPage - 1) * fenye.pagesize + 1}}
								</span></template>
						</el-table-column>
						<el-table-column prop="showname" label="店铺名称" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="ninetypename" label="场所类型" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="childninetype" label="小类型" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="address" label="地址" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="realcode" label="营业执照" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sanheyi" label="是否三合一" align="center" width="90" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sanheyi" label="任务情况" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<span v-if="scope.row.taskuuid">已建任务</span>
								<span v-else>未建任务</span>
							</template>
						</el-table-column>
						<el-table-column prop="siteman" label="店铺管理人"  width="100" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sitemanphone" label="管理人电话"  width="120" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="grid_name" label="网格名称" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="managename" label="网格员" align="center" height show-overflow-tooltip>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="360" align="center" height show-overflow-tooltip>
							<template slot-scope="scope">
								<el-button type="primary" size="small" @click="lookSiteDetail(scope.row)">场所详情</el-button>
								<el-button type="primary" size="small" @click="lookdetail(scope.row)">巡防详情</el-button>
								<el-button type="danger" size="small" @click="deleteDesc(scope.row)">删除</el-button>
								<!-- <el-button type="primary" size="small" @click="lookClick(scope.row)">更改网格员</el-button> -->
								<el-button type="primary" size="small" @click="exportInfo(scope.row)">巡防导出</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum"></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="更改网格员" width='600px' :visible.sync="xiangqing" :close-on-click-modal="false"
			:before-close="handleClose">
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="网格员">
					<el-select style="width: 100% !important;" v-model="form.manid" placeholder="请选择" clearable
						filterable>
						<el-option v-for="(item,index) in gridmanlist" :key="index" :label="item.name"
							:value="item.manid">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">提交</el-button>
					<el-button @click="close">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog append-to-body title="巡防详情" :close-on-click-modal="false" :visible.sync="detailVisible"
			width="1000px" @closed="siteuuid = null">
			<detail-cpn :taskId="siteuuid"></detail-cpn>
		</el-dialog>

		<el-dialog append-to-body title="场所详情" :close-on-click-modal="false" :visible.sync="siteDetailVisible"
			width="800px" @closed="siteuuid = null">
			<sitedetail :taskId="siteuuid"></sitedetail>
		</el-dialog>

		<el-dialog append-to-body title="统计" :close-on-click-modal="false" :visible.sync="totalVisible"
			width="800px" @closed="siteuuid = null">
			<total-page v-if="totalVisible"></total-page>
		</el-dialog>

		
		

		<el-dialog append-to-body title="删除" :close-on-click-modal="false" :visible.sync="deleteVisible"
			width="500px" @closed="siteuuid = null">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
				<el-form-item label="删除原因" prop="desc" :rules="[
					{ required: true, message: '删除原因不能为空'}
				]">
					<el-input type="textarea" rows="5" v-model="ruleForm.desc"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					<el-button @click="resetForm('ruleForm')">取消</el-button>
				</el-form-item>
			</el-form>
			
		</el-dialog>
	</div>
</template>

<script>
	import {
		NineSiteTree,
		NineSitePage,
		getGridManlist,
		NineSiteUpManage,
		NineSiteDetail,
		NineSiteExport,
		siteDetail
	} from '@/api/shaicha'
	import {
		patrolTaskExport,
		nineSiteDel
	} from '@/api/ninesite'
	import detailCpn from './sitelistdetail'
	import sitedetail from './sitedetail.vue'
	import * as FileSaver from 'file-saver'
	import TotalPage from './totalPage.vue'
	export default {
		components: {
			detailCpn,
			sitedetail,
			TotalPage
		},
		data() {
			return {
				detailVisible: false,
				siteDetailVisible: false,
				chaxun: {
					ninetype: '',
					showname: '',
					siteman: '',
					sitemanphone: '',
					managename: '',
					realcodeflag: '',	
					realcode: '',	
					sanheyi: '',	
					taskflag: '',
					renlingflag: '',
					childninetype: ''
				},
				siteuuid: null,
				gridmanlist: [],
				xiangqing: false,
				list: [],
				searchName: '',
				search: '',
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [8, 10, 20, 30, 40],
					pagesize: 8,
					totalnum: 0
				},
				form: {
					siteuuid: '',
					manid: '',
					nowmanid: ''
				},
				defaultProps: {
					children: 'list',
					label: 'tvalue'
				},
				tree: [],
				loading1: true,
				loading2: true,
				loading3: true,
				deleteVisible: false,
				ruleForm:{
					desc: ''
				},
				exportLoading: false,
				totalVisible: false
			};
		},
		mounted() {
			let that = this;
			that.getTree();
			that.getList();
		},
		methods: {
			openTotalPage(){
				this.totalVisible = true;
			},
			exportInfo(row) {
				let that = this;
				that.$confirm('是否确认导出该条任务的巡防流水?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					patrolTaskExport({
						ninesiteuuid: row.uuid
					}).then(res => {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
						

					})
				}).catch(() => {

				});
			},
			lookdetail(a) {
				let that = this;
				that.siteuuid = a.uuid;
				that.detailVisible = true;
			},
			lookSiteDetail(row){
				this.siteuuid = row.uuid;
				this.siteDetailVisible = true;
			},
			deleteDesc(row){
				// console.log(row)
				this.deleteVisible = true;
				this.form.siteuuid = row.uuid;
			},
			deleteFn(){
				this.$confirm('是否确认删除该场所?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					nineSiteDel({
						siteuuid: this.form.siteuuid,
						userid: this.GLOBAL.adminId(),
						operatevalue: this.ruleForm.desc
					}).then(res => {
						this.$message.success('操作成功');
						this.onsearch();
						this.resetForm('ruleForm');
					})
				}).catch(() => {

				});
			},
			submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.deleteFn();
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
				this.deleteVisible = false;
      },
			onSubmit() {
				var that = this;
				if (!that.form.manid) {
					that.$message.error('请选择网格员');
					return;
				}
				NineSiteUpManage({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					manid: that.form.manid,
					siteuuid: that.form.siteuuid
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							showClose: true,
							message: '更改成功',
							type: 'success'
						});
						that.getTree();
						that.getList();
						that.xiangqing = false;
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			close() {
				let that = this;
				that.xiangqing = false;
				that.form = {
					siteuuid: '',
					manid: '',
					nowmanid: ''
				};
			},
			lookClick(a) {
				let that = this;
				that.form.siteuuid = a.uuid;
				that.form.nowmanid = a.manid;
				that.xiangqing = true;
				getGridManlist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					manid: '',
					type: 1,
					page: 1
				}).then((res) => {
					that.loading3 = false;
					if (res.result == '200') {
						let list = res.detail;
						that.gridmanlist = [];
						for (let item of list) {
							if (item.manid != a.manid) {
								that.gridmanlist.push(item);
							}
						}
					} else {
						that.$message.error(res.description);
					}
				})

			},
			handleClose: function(done) {
				this.form = {
					manid: '',
					nowmanid: '',
					siteuuid: ''
				};
				done();
			},
			getTree() {
				this.loading1 = true;
				NineSiteTree({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId()
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						let tree = res.detail;
						let total = 0;
						for (let item of tree) {
							total += item.nums;
							item.tvalue = item.tvalue + '（' + item.nums + '）';
						}
						//客户需要添加“九小场所”这一级
						let nineTree = tree.slice(0, 9);
						let nineTreeTotal = 0;
						nineTree.map(item=>{
							nineTreeTotal += item.nums;
						})
						let nineTreeBig = {
							tvalue: '九小场所（' + nineTreeTotal + '）',
							tcode: '',
							list: [...nineTree]
						}

						let otherTree = tree.slice(10, 16);

						//客户需要添加“厂中厂”这一级
						let lastTree = tree.slice(16, tree.length+1);
						let lastTreeTotal = 0;
						lastTree.map(item=>{
							lastTreeTotal += item.nums;
						})
						let lastTreeBig = {
							tvalue: '厂中厂（' + lastTreeTotal + '）',
							tcode: '',
							list: [...lastTree]
						}

						this.tree = [{
							tvalue: '全部（' + total + '）',
							tcode: '',
							list: [nineTreeBig,  lastTreeBig, ...otherTree]
						}]
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			nodeClick(data) {
				// console.log(data)
				let chaxunTcode = '';
				if(data.tcode){
					chaxunTcode = data.tcode
				}else{
					let list = data.list; 
					let tcodes = [];
					list.map(item=>{
						tcodes.push(item.tcode);
					})
					chaxunTcode = tcodes.join(',')
				}
				

				var that = this;
				that.tableData = [];
				that.currentPage = 1;
				that.chaxun.ninetype = chaxunTcode;
				that.getList();
			},
			onsearch() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			//场所操作流水
			nineSiteFlow(){
				this.$router.push({
					path: '/ninesite/nineSiteflow'
				})
			},
			//导出
			exportNineSite(){
				this.exportLoading = true;
				NineSiteExport({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'ninetype': this.chaxun.ninetype,
					'showname': this.chaxun.showname,
					'siteman': this.chaxun.siteman,
					'sitemanphone': this.chaxun.sitemanphone,
					'managename': this.chaxun.managename,
					realcodeflag: this.chaxun.realcodeflag,
					childninetype: this.chaxun.childninetype,
					realcode: this.chaxun.realcode,
					sanheyi: this.chaxun.sanheyi,
					taskflag: this.chaxun.taskflag,
					renlingflag: this.chaxun.renlingflag,
					page: this.currentPage,
					pageSize: this.fenye.pagesize,
				}).then((res) => {
					this.exportLoading = false;
					if (res.result == '200') {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
					} else {
						this.$message.error(res.description);
					}
				})
			},
			getList() {
				var that = this;
				that.loading2 = true;
				NineSitePage({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					'ninetype': this.chaxun.ninetype,
					'showname': this.chaxun.showname,
					'siteman': this.chaxun.siteman,
					'sitemanphone': this.chaxun.sitemanphone,
					'managename': this.chaxun.managename,
					realcodeflag: this.chaxun.realcodeflag,
					childninetype: this.chaxun.childninetype,
					realcode: this.chaxun.realcode,
					sanheyi: this.chaxun.sanheyi,
					taskflag: this.chaxun.taskflag,
					renlingflag: this.chaxun.renlingflag,
					page: that.currentPage,
					pageSize: that.fenye.pagesize,
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.block {
		text-align: right;
	}

	.right .transformer {
		box-sizing: border-box;
		height: 100%;
		overflow-y: auto;
	}

	.topbox {
		height: 167px;
		margin-bottom: 28px;
	}

	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			// border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: '+';
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: '-';
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;
		}
	}



	.form3style {
		padding: 0 20px;

		.el-form-item {
			.spanstyle {
				padding-left: 25px;
				font-size: 20px;
				background: url(../../assets/imgs/edit.png) no-repeat left center;
				background-size: 20px auto;
				color: #5B93C1;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.detailtable {
		overflow: hidden;
		border-top: 1px solid #e3e4e3;
		border-left: 1px solid #e3e4e3;
		margin-bottom: 20px;
	}

	.detailtable p,
	.detailtable div {
		float: left;
		box-sizing: border-box;
		border-right: 1px solid #e3e4e3;
		border-bottom: 1px solid #e3e4e3;

	}

	.detailtable p {
		height: 37px;
		line-height: 37px;
		background-color: #e1f3fd;
		width: 100%;
		margin: 0;
		padding-left: 8px;
		color: #5b93c1;
		font-size: 16px;

	}

	.detailtable div {
		height: 36px;
		line-height: 36px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 10px;
	}

	.detailtable .labelname {
		width: 8%;
	}

	.detailtable .biaoshi {
		text-align: left;

	}
</style>