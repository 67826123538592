<template>
	<div class="panebox">

		<div class="pane">
			<div class="pane_tit">巡防任务</div>
			<div class="pane_cont">
				<el-row :gutter="20">
					<el-col :span="8">
						<div>店铺名称：<span>{{ taskInfo.showname }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>场所类型：<span>{{ taskInfo.ninetypename }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>社区管理员：<span>{{ taskInfo.managename }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>店铺管理人：<span>{{ taskInfo.siteman }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>管理人电话：<span>{{ taskInfo.sitemanphone }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>巡防周期：<span>{{ getOptionName(task.period, periodOptions) }}</span></div>
					</el-col>
					<!-- <el-col :span="8">
						<div>负责人：<span>{{ taskInfo.gridmanname }}</span></div>
					</el-col> -->
					<!-- <el-col :span="24">
						<div>经营范围：<span>{{ taskInfo.operate }}</span></div>
					</el-col> -->
					<el-col :span="24">
						<div>巡防地址：<span>{{ taskInfo.address }}</span></div>
					</el-col>
					<el-col :span="24">
						<div id="nineSiteDetailmap" style="height: 200px; width: 98%; margin: 10px auto 20px;"></div>
					</el-col>
					<el-col :span="8">
						<div>巡防次数：<span>{{ taskInfo.xunfangnums }}</span></div>
					</el-col>
					<el-col :span="8">
						<div>问题上报次数：<span>{{ taskInfo.xunfangwenti }}</span></div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="pane" v-if="flowList.length">
			<div class="pane_tit">巡防流水</div>
			<div class="pane_cont">
				<div class="flow">
					<div class="flowitem" v-for="item in flowList" :key="item.id">
						<div class="icon"></div>
						<div class="desc">
							<div style="align-items: center;">
								<div class="filed">巡防时间：</div>
								<div>{{ item.doing_time }}</div>
								<div v-if="item.gridman_name">，{{ item.gridman_name }}</div>
								<div v-if="item.problem == 1">，<span style="color: #ee9934 ">发现问题</span></div>
							</div>
							<div v-if="item.problem_description">
								<div class="filed">问题描述：</div>
								<div style="color: #dd4a68;" v-html="item.problem_description.split('/n').join('<br/>')"></div>
							</div>
							<div v-if="item.problem_attachment_path.length>0">
								<div class="filed">相关图片：</div>
								<div class="imgs">
									<el-image style="height: 90px; width: auto; margin-right: 10px;"
										v-for="(imgurl,index) in item.problem_attachment_path" :key="index"
										:src="imgurl" :preview-src-list="item.problem_attachment_path">
									</el-image>
								</div>
							</div>

							<!-- 处理 -->
							<div v-if="item.deal_manname">
								<div class="filed">处理人：</div>
								<div>{{ item.deal_manname }}</div>
							</div>
							<div v-if="item.deal_description">
								<div class="filed">处理描述：</div>
								<div style="color: #dd4a68;">{{ item.deal_description }}</div>
							</div>
							<div v-if="item.deal_attachment_path.length">
								<div class="filed">相关图片：</div>
								<div class="imgs">
									<el-image style="height: 90px; width: auto; margin-right: 10px;"
										v-for="(imgurl,index) in item.deal_attachment_path" :key="index" :src="imgurl"
										:preview-src-list="item.deal_attachment_path">
									</el-image>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pagebox">
					<el-pagination :hide-on-single-page="true" background @current-change="handleCurrentChange"
						:current-page.sync="currentPage" :page-size="pageSize" layout="total, prev, pager, next"
						:total="totalRow">
					</el-pagination>
				</div>
			</div>

		</div>
		<el-dialog append-to-body title="巡防地点" :close-on-click-modal="false" :visible.sync="detailVisible"
			width="1000px">
			<div id="nineSiteDetailmap2" style="height: 400px; width: 98%; margin: 10px auto 20px;"></div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import {
		taskFlow
	} from '@/api/ninesite'
	import {
		NineSiteDetail
	} from '@/api/shaicha'
	export default {
		name: "detail",
		components: {},
		props: {
			taskId: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				detailVisible: false,
				currentPage: 1,
				pageSize: 10,
				totalRow: 0,
				periodOptions: [{
					label: '按周',
					tip: '告警时间 周四',
					value: 0
				}, {
					label: '按月',
					tip: '告警时间 每月20号',
					value: 1
				}, {
					label: '按季度',
					tip: '告警时间 每季度最后一个月',
					value: 2
				}, {
					label: '按年',
					tip: '告警时间 每年最后以一个月',
					value: 3
				}],
				taskInfo: {},
				task: {},
				flowList: []
			}
		},
		watch: {
			taskId: {
				handler(newVal, oldVal) {
					if (newVal) {
						this.getInfo();
						this.getFlows();
					}
				},
				immediate: true
			}
		},
		created() {},
		mounted() {},
		computed: {},
		methods: {
			//加载地图
			getmap(center) {
				lazyAMapApiLoaderInstance.load().then(() => {
					let map = new AMap.Map('nineSiteDetailmap', {
						center,
						zoom: 20,
					})

					let marker = new AMap.Marker({
						position: center,
						offset: new AMap.Pixel(-13, -30)
					});
					marker.setMap(map);
				})
			},
			//获取任务详情
			getInfo() {
				let that = this;
				NineSiteDetail({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					siteuuid: that.taskId
				}).then(res => {
					if (res.result == 200) {
						console.log(res)
						let site = res.detail.site;
						this.taskInfo = site;
						this.task = res.detail.task;
						let center = site.addressxy ? site.addressxy.split(',') : [];
						this.getmap(center)
					} else {
						that.$message.warning(res.description)
					}
				})
			},
			//获取任务流水
			getFlows() {
				taskFlow({
					ninesiteuuid : this.taskId,
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.result == 200) {
						console.log(res)
						this.flowList = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			getOptionName(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getFlows();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.pane {
		margin-bottom: 30px;
		border: 1px solid #ddd;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

		.pane_tit {
			padding: 10px 20px;
			border-bottom: 1px solid #ddd;
			background: #f9f9f9;
			font-size: 16px;
			color: #333;
		}

		.pane_cont {
			padding: 20px;
			background: #fff;

			.el-row {
				>div {
					margin-bottom: 14px;
					font-weight: bold;
					color: #333;

					span {
						font-weight: normal;
						color: #666;
					}
				}
			}

			.flow {
				max-height: 360px;
				overflow: auto;
			}

			.flowitem {
				position: relative;
				padding-left: 30px;

				.icon {
					position: absolute;
					height: 100%;
					background: #e4e7ed;
					width: 1px;
					top: 15px;

					&::before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						transform: translate(-50%, -50%);
						width: 15px;
						height: 15px;
						background: #e4e7ed;
						border-radius: 50%;
					}
				}

				&:last-child {
					.icon {
						height: 0;
					}
				}

				.desc {
					padding: 5px 30px 40px;
					min-height: 40px;
					line-height: 1.6em;

					>div {
						display: flex;
						margin-bottom: 5px;

						.filed {
							width: 120px;
							text-align: right;
						}
					}

					.imgs {
						display: flex;

						img {
							height: 90px;
							width: auto;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	.pagebox {
		padding-top: 20px;
	}
</style>